<template>
    <div id="hyundai_container">
        <!--############## Section1 ##############-->
        <section class="section1">
            <h1 class="section_title">
                <slot name="section_title"> </slot>
            </h1>
            <figure class="section_aplayz_logo">
                <img src="/media/img/landing/hynudai/aplayz_log.svg" alt="aplayz_log" />
            </figure>
            <div class="section1_imggroup">
                <slot name="section1_imggroup"> </slot>
            </div>
            <slot name="gradient"></slot>
        </section>
        <!--############## Section2 ##############-->
        <section class="section2">
            <h1 class="section2_title">
                <slot name="section2_title"></slot>
            </h1>
            <figure class="car_icon">
                <slot name="section2_icon1"></slot>
            </figure>
            <p class="section2_desc">
                <slot name="section2_desc1"></slot>
            </p>
            <figure class="music_icon">
                <slot name="section2_icon2"></slot>
            </figure>
            <p class="section2_desc">
                <slot name="section2_desc2"></slot>
            </p>
            <h1 class="section2_title2">
                <slot name="section2_title2"> 이제 <em class="emphasis">APLAYZ</em> 와 함께 해보세요. </slot>
            </h1>
            <p class="section2_desc mt24">
                <slot name="section2_desc3"></slot>
            </p>
            <slot name="introduce_video">
                <video
                    class="introduce_video"
                    poster="/media/img/landing/hynudai/thumbnail.png"
                    src="/media/video/hyundai/aplayz_introduce.mp4"
                    controls
                ></video>
            </slot>
        </section>
        <!--############## Section3 ##############-->
        <section class="section3">
            <div class="wrap_benefits">
                <slot name="benefits_wrap">
                    <div class="benefits">
                        <span class="round">혜택</span>
                    </div>
                </slot>
            </div>
            <p class="section3_title">
                <slot name="section3_title"></slot>
            </p>
            <figure class="section3_3cupon">
                <slot name="section3_3cupon_img"></slot>
            </figure>
            <div class="benefits">
                <span class="round">사용방법</span>
            </div>
            <ul class="use_desc">
                <li class="item">아래 ‘쿠폰 등록 바로가기' 클릭</li>
                <li class="arrow_down">
                    <img class="arrow_down_icon" src="/media/img/landing/hynudai/arrow_down.png" alt="arrow_down_icon" />
                </li>
                <li class="item">로그인/회원가입 진행</li>
                <li class="arrow_down">
                    <img class="arrow_down_icon" src="/media/img/landing/hynudai/arrow_down.png" alt="arrow_down_icon" />
                </li>
                <li class="item">
                    우측 상단 아이콘 <img class="hambuger" src="/media/img/landing/hynudai/hambuger.png" alt="hambuger" />
                    → 쿠폰
                    <br />
                    → 발급받은 쿠폰 번호 등록/사용하여 구독
                </li>
            </ul>
            <div class="register_wrap">
                <slot name="register_wrap">
                    <button class="hyundai_button" @click="goHome">쿠폰 등록 바로가기</button>
                </slot>
            </div>
        </section>
        <!--############## Section4 ##############-->
        <section class="section4">
            <p class="section4_title">
                <slot name="section4_title">
                    세상 모든 공간을 <br />
                    PLAY하라!
                </slot>
            </p>

            <slot name="section4_desc">
                <p class="section4_desc">
                    이순간 내 공간에 <br />
                    가장 어울리는 노래를 <br />
                    PLAY해보세요.
                </p>
            </slot>

            <slot name="section4_revu"> </slot>
        </section>
        <!--############## Section5 ##############-->
        <section class="section5">
            <slot name="section5_title">
                <h1 class="section5_title">어플레이즈(APLAYZ)란?</h1>
            </slot>

            <p class="section5_desc desc1">
                어플레이즈는 공간에 최적화된 음악을 AI 큐레이션을 <br />
                통해 끊임없이 자동 추천하여 재생하는 <br />
                <span class="emphasis"> 공간 맞춤 음악 큐레이션 서비스</span>입니다.
            </p>
            <p class="section5_desc desc2">
                공간별 고유 정보와 사용자의 음악 취향에 날씨,<br />
                시간까지 고려하여 <span class="emphasis"> 공간별로 어울리는 음악을</span> <br />
                <span class="emphasis">실시간으로 추천</span>해드립니다.
            </p>

            <slot name="card_group">
                <ul class="card_group">
                    <li class="card_item first">
                        <div class="card_item_head">
                            <span class="order">1</span>
                            <h1 class="card_item_title">AI 기반 차량 최적 큐레이션 음악 제공</h1>
                        </div>
                        <div class="card_item_body">
                            <div class="body_item first">
                                <figure class="done_svg">
                                    <img class="done_img" src="/media/img/landing/hynudai/done.svg" alt="done" />
                                </figure>

                                <p class="body_item_desc">
                                    교통정보, 시간/날씨/계절 등의 실시간 주행 환경 정보를 반영하여 음악을 자동추천합니다.
                                </p>
                            </div>
                            <div class="body_item">
                                <figure class="done_svg">
                                    <img class="done_img" src="/media/img/landing/hynudai/done.svg" alt="done" />
                                </figure>

                                <p class="body_item_desc">차량 특성 정보를 기반으로 실시간 최적의 음악을 자동 추천/제공합니다.</p>
                            </div>

                            <figure class="carditem1_img_group">
                                <img class="carditem1_img" src="/media/img/landing/hynudai/carditem1_img1.png" alt="carditem1_img1" />
                                <img class="carditem1_img" src="/media/img/landing/hynudai/carditem1_img2.png" alt="carditem1_img2" />
                            </figure>
                        </div>
                    </li>
                    <li class="card_item">
                        <div class="card_item_head">
                            <span class="order">2</span>
                            <h1 class="card_item_title">이용 상황 별 큐레이션 정교화</h1>
                        </div>
                        <div class="card_item_body">
                            <div class="body_item first">
                                <figure class="done_svg">
                                    <img class="done_img" src="/media/img/landing/hynudai/done.svg" alt="done" />
                                </figure>

                                <p class="body_item_desc">
                                    출근, 퇴근, 드라이브 등 차량 이용 목적에 맞는 음악 큐레이션을 자동 제공해드립니다.
                                </p>
                            </div>
                            <div class="body_item">
                                <figure class="done_svg">
                                    <img class="done_img" src="/media/img/landing/hynudai/done.svg" alt="done" />
                                </figure>

                                <p class="body_item_desc">운전자의 피드백 또한 실시간으로 반영되어 플레이리스트가 업데이트됩니다.</p>
                            </div>
                        </div>
                        <figure class="carditem2_img_group">
                            <img class="carditem2_img" src="/media/img/landing/hynudai/carditem2_img1.png" alt="carditem1_img1" />
                            <img class="carditem2_img" src="/media/img/landing/hynudai/carditem2_img2.png" alt="carditem1_img2" />
                        </figure>
                    </li>
                    <li class="card_item">
                        <div class="card_item_head">
                            <span class="order">3</span>
                            <h1 class="card_item_title">애플 카플레이/안드로이드 오토 지원</h1>
                        </div>
                        <div class="card_item_body">
                            <div class="body_item first">
                                <figure class="done_svg">
                                    <img class="done_img" src="/media/img/landing/hynudai/done.svg" alt="done" />
                                </figure>

                                <p class="body_item_desc">
                                    애플 카플레이/안드로이드 오토를 지원하여 차량에서 보다 편리하게 이용 가능합니다.
                                </p>
                            </div>
                            <div class="body_item">
                                <figure class="done_svg">
                                    <img class="done_img" src="/media/img/landing/hynudai/done.svg" alt="done" />
                                </figure>

                                <p class="body_item_desc">
                                    손쉬운 조작으로 선호 음악 분위기 및 차량 이용 목적을 선택하고 맞춤형 음악 큐레이션을 받아보실 수
                                    있습니다.
                                </p>
                            </div>
                        </div>

                        <figure class="carditem3_img_group">
                            <img class="carditem3_img" src="/media/img/landing/hynudai/carditem3.png" alt="carditem3" />
                        </figure>
                    </li>
                </ul>
            </slot>
            <h1 class="section5_title2">지금 바로 어플레이즈를 이용해보세요!</h1>
            <div class="shortcuts_wrap">
                <button class="hyundai_button shortcuts" @click="goHome">어플레이즈 바로가기</button>
            </div>
            <div class="section5_title3_wrap">
                <figure class="m_logo_wrap">
                    <img class="m_logo_imgs" src="/media/img/landing/hynudai/aplayz_m_logo.svg" alt="어플레이즈로고" />
                </figure>

                <span class="section5_title3">세상 모든 공간을 PLAY하라!</span>
            </div>
            <ul class="section5_app_store_group">
                <li class="download" @click="clickAppStore('AppleStore')">
                    <figure class="downlaod_icon">
                        <img class="downlaod_icon_img" src="/media/img/landing/hynudai/m_apple_store.png" alt="appleStore" />
                    </figure>
                    <span class="download_text">App Store</span>
                </li>
                <li class="download left" @click="clickAppStore('GoogleStore')">
                    <figure class="downlaod_icon">
                        <img class="downlaod_icon_img" src="  /media/img/landing/hynudai/m_google_store.png" alt="appleStore" />
                    </figure>
                    <span class="download_text">Google Play</span>
                </li>
            </ul>
        </section>
    </div>
</template>
<script>
export default {
  name: 'LandingSlot',

  data () {
    return {};
  },

  mounted () {},

  methods: {
    goHome () {
      const route = this.$router.resolve({ name: 'Home' });
      window.open(route.href, '_blank');
    },
    clickAppStore (storeGb = 'GoogleStore') {
      if (storeGb === 'GoogleStore') {
        // 안드로이드
        window.open('https://play.google.com/store/apps/details?id=com.mobileaos.aplayz', 'blank');
      } else if (storeGb === 'AppleStore') {
        // 아이폰
        window.open('https://apps.apple.com/kr/app/aplayz/id6446978790', 'blank');
      }
    }
  }
};
</script>
<style scoped src="@/assets/css/landing/hyundai.css"></style>
<style scoped>
#pc_player {
  width: 214px;
  height: 177px;
}

.section1 .section1_imggroup {
  margin-top: 27px;
}
</style>
